import React from "react";
// import soilMoistureTriangle from "../img/soil-texture-classification.svg";
import {useTranslation} from "react-i18next";
// import soilMoistureTriangleEl from "../img/soil-texture-classification_el.svg";

const SoilMoistureTriangleComponent = ({ lang = 'en', sand, clay, soilType }) => {
    const { t } = useTranslation();
    return (
        <div className="soil-texture-classification">
            <span className="label-x">{t('Sand')} (%)</span>
            <span className="label-y">{t('Clay')} (%)</span>
            <div className="x-axis">
                <span>0</span>
                <span>10</span>
                <span>20</span>
                <span>30</span>
                <span>40</span>
                <span>50</span>
                <span>60</span>
                <span>70</span>
                <span>80</span>
                <span>90</span>
            </div>
            <div className="y-axis">
                <span>10</span>
                <span>20</span>
                <span>30</span>
                <span>40</span>
                <span>50</span>
                <span>60</span>
                <span>70</span>
                <span>80</span>
                <span>90</span>
            </div>
            {/*<img*/}
            {/*    width="100%"*/}
            {/*    height="auto"*/}
            {/*    src={soilMoistureTriangle}*/}
            {/*    // src={lang === 'en' ? soilMoistureTriangle : soilMoistureTriangleEl}*/}
            {/*/>*/}

            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                stroke-width="0.5"
                stroke="#000"
            >
                <polygon points="0,0 0,40 0,40 20,60, 45,60 45,45 40,40 " fill="#e6eaf1">
                    <title>{t('Clay')}</title>
                </polygon>
                <polygon points="0,40 0,60, 20,60 " fill="#e6eaf1">
                    <title>{t('Silty Clay')}</title>
                </polygon>
                <polygon points="0,60 0,73, 20,73 20,60" fill="#e6eaf1">
                    <title>{t('Silty Clay Loam')}</title>
                </polygon>
                <polygon points="20,60 20,73 45,73 45,60" fill="#e6eaf1">
                    <title>{t('Clay Loam')}</title>
                </polygon>
                <polygon points="45,45 45,65 65,65" fill="#e6eaf1">
                    <title>{t('Sandy Clay')}</title>
                </polygon>
                <polygon points="45,65 45,73 52,80 80,80 65,65" fill="#e6eaf1">
                    <title>{t('Sandy Clay Loam')}</title>
                </polygon>

                <polygon points="0,88, 0,100 20,100 10,88" fill="#efe6f1">
                    <title>{t('Silt')}</title>
                </polygon>
                <polygon points="0,73 0,88 10,88 20,100 50,100 26,73" fill="#efe6f1">
                    <title>{t('Silt Loam')}</title>
                </polygon>
                <polygon points="45,73 52,80 52,93  44,93 26,73 " fill="#efe6f1">
                    <title>{t('Loam')}</title>
                </polygon>

                <polygon points="52,80 52,93  44,93 50,100 70,100 85,85 80,80 " fill="#e1e0d7">
                    <title>{t('Sandy Loam')}</title>
                </polygon>
                <polygon points="70,100 85,100 90,90 85,85 " fill="#e1e0d7">
                    <title>{t('Loamy Sand')}</title>
                </polygon>
                <polygon points="90,90 85,100 100,100 " fill="#e1e0d7">
                    <title>{t('Sand')}</title>
                </polygon>


                <line stroke-width="1" x1="0" y1="100" x2="100" y2="100%" stroke="black"/>
                <line stroke-width="1" x1="0" y1="0" x2="0" y2="100%" stroke="black"/>

                <text y="57" x="2" className="label">SiC</text>
                <text y="38" x="16" className="label" text="Clay">Cl</text>
                <text y="60" x="46" className="label">SC</text>
                <text y="69" x="2" className="label">SCL</text>
                <text y="69" x="25" className="label">CL</text>
                <text y="74" x="51" className="label">SCL</text>

                <text y="97.5" x="89" className="label">S</text>
                <text y="97" x="76" className="label">LS</text>
                <text y="92" x="58" className="label">SL</text>

                <text y="83" x="41" className="label">L</text>
                <text y="88" x="18" className="label">SiL</text>
                <text y="96" x="4" className="label">Si</text>
            </svg>


            {(sand && clay) && (
                <div className="dot" style={{left: `${sand}%`, bottom: `${clay}%`}}></div>
            )}

            <span className="values">
                <span>{t('Soil texture')}:</span><br /><strong>{soilType}</strong><br/>
                <span className="text-muted">{t('Clay')}:</span> <strong>{clay ? (clay+ '%') : '-'}</strong><br/>
                <span className="text-muted"> {t('Sand')}:</span> <strong>{sand ? (sand + '%') : '-'}</strong><br/>
                <span className="text-muted"> {t('Silt')}:</span> <strong>{(clay && sand) ? (100 - (sand + clay)) + '%' : '-'}</strong><br/>
            </span>
        </div>
    );
};

export default SoilMoistureTriangleComponent;