import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const WeatherChart = ({ weatherData }) => {
  const datasets = [
    {
      label: 'Temperature (°C)',
      data: weatherData.map(data => data.temperature),
      // borderColor: 'red',
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'red',
      borderWidth: 1,
      yAxisID: 'y',
      xAxisID: 'x',
    },
    {
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      label: 'Precipitation (mm)',
      data: weatherData.map(data => data.precipitation),
      backgroundColor: 'blue',
      yAxisID: 'y1',
      xAxisID: 'x',
      borderWidth: 1,
      type: 'bar',

    },
    {
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      label: 'Relative Humidity (%)',
      data: weatherData.map(data => data.relative_humidity),
      borderColor: 'green',
      yAxisID: 'y2',
      xAxisID: 'x',
      borderWidth: 1,
      // hidden: true,
    },
    {
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      label: 'Wind speed (km/h)',
      data: weatherData.map(data => data.hourly_wind_speed_10m),
      borderColor: 'orange',
      yAxisID: 'y3',
      xAxisID: 'x',
      borderWidth: 1,
      // hidden: true,
    }
    // ,
    // {
    //   label: 'Relative Humidity (%)',
    //   data: weatherData.map(data => data.humidity),
    //   borderColor: 'purple',
    //   yAxisID: 'y3',
    //   xAxisID: 'x',
    // }
  ];

  const scales = {
    x: {
      type: 'time',
      time: {
        unit: "day",
        unitStepSize: 1000,
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD',
          week: 'MMM DD',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD',
        }
      },
      title: {
        display: false,
        text: 'Date'
      }
    },
    y: {
      type: 'linear' ,
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
    y3: {
      type: 'linear',
      display: true,
      position: 'left',
      grid: {
        drawOnChartArea: false,
      },
      // Adjust the scale accordingly
      ticks: {
        callback: function(value, index, ticks) {
          return value + ' km/h';
        }
      }
    },
    y2: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
      // Adjust the scale accordingly
      ticks: {
        callback: function(value, index, ticks) {
          return value + '%';
        }
      }
    }
  };

  const data = {
    labels: weatherData.map(data => data.datetime), // Assuming dateTime is a string
    datasets,
  };

  const options = {
    scales,
  };

  return (
    <>
      <div className="d-norne d-lg-block">
        <Line
          data={{
            labels: weatherData.map(data => data.datetime), // Assuming dateTime is a string
            datasets: [datasets[0], datasets[1]],
          }}
          options={{
            scales: {
              y: options.scales.y,
              y1: options.scales.y1,
              x: scales.x,
            },
          }}
        />
      </div>

      <div className="d-nfone d-lg-block mt-4">
        <Line
          data={{
            labels: weatherData.map(data => data.datetime), // Assuming dateTime is a string
            datasets: [datasets[2], datasets[3]],
          }}
          options={{
            scales: {
              y2: options.scales.y2,
              y3: options.scales.y3,
              x: scales.x,
            },
          }}
        />
      </div>
    </>
  );
};

export default WeatherChart;
